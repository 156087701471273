@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --scrollbar-track-color: transparent;
  --scrollbar-thumb-color: theme(colors.medium);
}

html,
body {
  @apply bg-dark text-light;
}

h1 {
  @apply text-3xl font-bold;

  @media screen(lg) {
    @apply text-4xl;
  }
}

h2 {
  @apply text-xl font-bold;

  @media screen(lg) {
    @apply text-2xl;
  }
}

p {
  @apply text-base;

  @media screen(lg) {
    @apply text-lg;
  }
}

li {
  @media screen(lg) {
    @apply text-lg;
  }
}

section {
  @apply py-20 px-4;

  &:nth-child(odd) {
    @apply bg-light text-dark;
  }

  @media screen(sm) {
    @apply px-8;
  }
}

label {
  @apply mx-auto w-full max-w-screen-sm;
}

label,
textarea,
input {
  @apply block;
}

textarea:disabled,
input:disabled {
  @apply bg-stone-400;
}

@layer utilities {
  .thin-scrollbar {
    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      @apply h-1.5;
    }

    &::-webkit-scrollbar:vertical,
    &::-webkit-scrollbar-corner {
      @apply w-0;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      @apply rounded-lg;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--scrollbar-track-color);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb-color);
    }
  }
}
