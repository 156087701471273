media-controller {
  @apply overflow-hidden rounded-full;
}

media-play-button,
media-time-display,
media-time-range,
media-volume-range {
  @apply bg-dark;
}

media-time-range {
  @apply pr-1.5;
}
